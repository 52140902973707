import { memo, useContext } from 'react';
import { MeasurementContext } from '@context/MeasurementContext';
import { useAvatarViewerSelector } from '@context/AvatarViewerContext';
import {
  makeStyles,
  Box,
  FormControlLabel,
  Checkbox,
  Slider,
  useMediaQuery,
} from '@material-ui/core';
import { ReactComponent as LockIcon } from '@assets/icons/ic_locked.svg';
import { ReactComponent as CheckboxOnIcon } from '../../img/checkbox-on.svg';
import { ReactComponent as CheckboxOffIcon } from '../../img/checkbox-off.svg';

const useStyles = makeStyles(({ breakpoints }) => ({
  interactiveSlider: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    [breakpoints.up('lg')]: {
      position: 'absolute',
      top: 8,
      right: 10,
    },
  },
  sliderValue: {
    textAlign: 'left',
    width: 210,
    maxWidth: 210,
    margin: 0,
  },
  torsoSlider: {
    position: 'absolute',
    marginTop: 0,
    right: 30,
    bottom: 110,
    height: 'clamp(200px, 30vh, 300px) !important',
    [breakpoints.up('lg')]: {
      top: 120,
      right: 30,
    },
  },
  sliderLabel: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    maxWidth: 210,
    width: 210,
    margin: 0,
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#000',
    },
    '& .Mui-disabled': {
      color: '#000',
    },
  },
  checkBox: {
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:disabled': {
      cursor: 'not-allowed',
    },
    checkBox: {
      paddingRight: 0,
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },
  },
}));

const SliderBar = () => {
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const { torsoSlider, sliderValue } = useStyles();
  const {
    sliderSlice,
    units,
    currentCircum,
    floorLevel,
    currentHeight,
    convertToCm,
    sliderChange,
    sliderPercent,
  } = useAvatarViewerSelector((state) => ({
    sliderSlice: state.sliderSlice,
    units: state.units,
    currentCircum: state.currentCircum,
    floorLevel: state.floorLevel,
    currentHeight: state.currentHeight,
    convertToCm: state.convertToCm,
    sliderChange: state.sliderChange,
    sliderPercent: state.sliderPercent,
  }));
  if (!sliderSlice) return null;
  if (!isMobile) {
    return (
      <Box sx={{ height: 200 }}>
        <h3 className={sliderValue}>
          Circumference:{' '}
          {units === 'in' ? currentCircum : convertToCm(currentCircum)} {units}
        </h3>
        {typeof floorLevel === 'number' && (
          <h3 className={sliderValue}>
            Height:{' '}
            {units === 'in' ? currentHeight : convertToCm(currentHeight)}{' '}
            {units}
          </h3>
        )}
        <Slider
          className={torsoSlider}
          aria-label="Height"
          orientation="vertical"
          value={sliderPercent}
          track={false}
          onChange={sliderChange}
          min={1}
          color="secondary"
        />
      </Box>
    );
  }
  return (
    <Box>
      <Slider
        className={torsoSlider}
        aria-label="Height"
        orientation="vertical"
        value={sliderPercent}
        track={false}
        onChange={sliderChange}
        min={1}
        color="secondary"
      />
      <h3 className={sliderValue}>
        Circumference:{' '}
        {units === 'in' ? currentCircum : convertToCm(currentCircum)} {units}
      </h3>
      {typeof floorLevel === 'number' && (
        <h3 className={sliderValue}>
          Height: {units === 'in' ? currentHeight : convertToCm(currentHeight)}{' '}
          {units}
        </h3>
      )}
    </Box>
  );
};

const SliderCheckbox = () => {
  const { sliderLabel, checkBox } = useStyles();
  const {
    validSliderVersion,
    metricsLoading,
    isBasicPlan,
    toggleSlider,
    sliderReady,
    loadingMetrics,
    showSlider,
  } = useAvatarViewerSelector((state) => ({
    validSliderVersion: state.validSliderVersion,
    metricsLoading: state.metricsLoading,
    isBasicPlan: state.isBasicPlan,
    toggleSlider: state.toggleSlider,
    sliderReady: state.sliderReady,
    loadingMetrics: state.loadingMetrics,
    showSlider: state.showSlider,
  }));
  const { avatarIsPending } = useContext(MeasurementContext);
  if (validSliderVersion && !metricsLoading && sliderReady) {
    return (
      <FormControlLabel
        value="slider"
        className={sliderLabel}
        disabled={loadingMetrics || isBasicPlan || avatarIsPending}
        control={
          <Checkbox
            disableRipple
            className={checkBox}
            checkedIcon={<CheckboxOnIcon />}
            icon={
              isBasicPlan || avatarIsPending ? (
                <LockIcon />
              ) : (
                <CheckboxOffIcon />
              )
            }
            onClick={toggleSlider}
            checked={showSlider}
            disabled={isBasicPlan || metricsLoading || avatarIsPending}
          />
        }
        label="Measurement Slider"
        labelPlacement="end"
      />
    );
  }
  return null;
};

export const InteractiveSlider = memo(() => {
  const { interactiveSlider } = useStyles();
  const isMobile = useMediaQuery('(max-width: 1200px)');

  return (
    <Box className={interactiveSlider}>
      {isMobile ? (
        <>
          <SliderBar />
          <SliderCheckbox />
        </>
      ) : (
        <>
          <SliderCheckbox />
          <SliderBar />
        </>
      )}
    </Box>
  );
});
InteractiveSlider.displayName = 'InteractiveSlider';
