/* eslint-disable no-use-before-define */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-classes-per-file */
import { createContext, useState } from 'react';
import { useUser } from '@hooks/useUser';
import { useQueryClient } from 'react-query';
import { db } from '../config/firebase';

const MeasurementContext = createContext({});

class Source {
  constructor(collection, id) {
    this.collection = collection;
    this.id = id;
    this.girthIdToKey = {};
    this.lengthIdToKey = {};
  }
}

const sources = {
  inhouse: new Source('metrics_ttf', 'ttfId'),
  external: new Source('metrics', 'externalId'),
};

class Metric {
  constructor(label, hoverText, ttfId, externalId, isLocked = false) {
    this.isSelected = false;
    this.hoverText = hoverText;
    this.label = label;
    this.line = null;
    this.points = [];
    this.value = 0;
    this.ttfId = ttfId;
    this.externalId = externalId;
    this.isLocked = isLocked;
  }

  lock() {
    this.isLocked = true;
    this.value = '?';
  }

  setValue(value) {
    if (!this.isLocked) {
      this.value = value;
    }
  }
}

const LengthsLegMetrics = [
  'Right Outseam Waist To Floor',
  'Left Outseam Waist To Floor',
  'Right Outseam Waist To Knee',
  'Left Outseam Waist To Knee',
  'Total Crotch Length',
  'Front Crotch Length',
  'Back Crotch Length',
  'Front Rise - High',
  'Back Rise - High',
  'Front Rise - Mid',
  'Back Rise - Mid',
  'Front Rise - Low',
  'Back Rise - Low',
];

const CircumferenceLegMetrics = [
  'Right Thigh',
  'Left Thigh',
  'Right Knee',
  'Left Knee',
  'Right Calf',
  'Left Calf',
  'Right Ankle',
  'Left Ankle',
];

const MeasurementContextProvider = ({ children }) => {
  const { data } = useUser();
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [avatarIsPending, setAvatarIsPending] = useState(true);
  const queryClient = useQueryClient();

  const getMetrics = async (scanId, hasLegScan) => {
    setMetricsLoading(true);
    const circData = {
      Neck: new Metric('Neck', 'Circumference around neck', 21, 140),
      'Neck Base': new Metric(
        'Neck Base',
        'Circumference around the base of the neck',
        18,
        127
      ),
      'Upper Chest': new Metric(
        'Upper Chest',
        'Circumference just below the armpits',
        4,
        105
      ),
      Overbust: new Metric(
        'Overbust',
        'Circumference measured from the underbust level at center back, under the armpits, and above the bust at the front',
        20,
        -1
      ),
      Bust: new Metric(
        'Bust',
        'Circumference around the fullest part of the bust',
        2,
        144
      ),
      'Under Bust': new Metric(
        'Under Bust',
        'Circumference directly below bust',
        9,
        106
      ),
      Waist: new Metric(
        'Waist',
        'Circumference measured at the natural waist level',
        3,
        108
      ),
      'Minimum Torso': new Metric(
        'Minimum Torso',
        'Minimum circumference of the torso between the chest and hips',
        6,
        155
      ),
      'Waistband - High': new Metric(
        'Waistband - High',
        'Waistband circumference measured at high rise level',
        32,
        -1
      ),
      'Waistband - Mid': new Metric(
        'Waistband - Mid',
        'Waistband circumference measured at mid rise level',
        31,
        -1
      ),
      'Waistband - Low': new Metric(
        'Waistband - Low',
        'Waistband circumference measured at low rise level',
        30,
        -1
      ),
      /*
      'High Hip': new Metric(
        'High Hip',
        'Circumference around the torso at the level of the pelvic bone',
        -1, // 19,
        -1
      ),
      */
      Hip: new Metric(
        'Hip',
        'Circumference around the fullest part of the hips, typically around the buttocks',
        5,
        167
      ),
      'Right Armscye': new Metric(
        'Right Armscye',
        'Right armhole circumference over the top of the shoulder and under the armpit',
        29,
        -1
      ),
      'Left Armscye': new Metric(
        'Left Armscye',
        'Left armhole circumference over the top of the shoulder and under the armpit',
        28,
        -1
      ),
      'Right Bicep': new Metric(
        'Right Bicep',
        'Maximum circumference around the upper right arm',
        23,
        126
      ),
      'Left Bicep': new Metric(
        'Left Bicep',
        'Maximum circumference around the upper left arm',
        22,
        125
      ),
      'Right Elbow': new Metric(
        'Right Elbow',
        'Circumference around the right elbow joint',
        27,
        -1
      ),
      'Left Elbow': new Metric(
        'Left Elbow',
        'Circumference around the left elbow joint',
        26,
        -1
      ),
      'Right Wrist': new Metric(
        'Right Wrist',
        'Circumference around the right wrist joint',
        25,
        121
      ),
      'Left Wrist': new Metric(
        'Left Wrist',
        'Circumference around the left wrist joint',
        24,
        123
      ),
      'Right Thigh': new Metric(
        'Right Thigh',
        'Maximum circumference around the right thigh below the buttocks',
        13,
        142
      ),
      'Left Thigh': new Metric(
        'Left Thigh',
        'Maximum circumference around the left thigh below the buttocks',
        12,
        141
      ),
      'Right Knee': new Metric(
        'Right Knee',
        'Right knee circumference through center of kneecap',
        11,
        113
      ),
      'Left Knee': new Metric(
        'Left Knee',
        'Left knee circumference through center of kneecap',
        10,
        114
      ),
      'Right Calf': new Metric(
        'Right Calf',
        'Maximum circumference around the right calf below the knees and above the ankles',
        15,
        137
      ),
      'Left Calf': new Metric(
        'Left Calf',
        'Maximum circumference around the left calf below the knees and above the ankles',
        14,
        136
      ),
      'Right Ankle': new Metric(
        'Right Ankle',
        'Minimum circumference around the right leg just above the ankle bone',
        17,
        139
      ),
      'Left Ankle': new Metric(
        'Left Ankle',
        'Minimum circumference around the left leg just above the ankle bone',
        16,
        138
      ),
    };

    const circOrder = [
      'Neck',
      'Neck Base',
      'Upper Chest',
      'Overbust',
      'Bust',
      'Under Bust',
      'Waist',
      'Minimum Torso',
      'Waistband - High',
      'Waistband - Mid',
      'Waistband - Low',
      // 'High Hip',
      'Hip',
      'Right Armscye',
      'Left Armscye',
      'Right Bicep',
      'Left Bicep',
      'Right Elbow',
      'Left Elbow',
      'Right Wrist',
      'Left Wrist',
      'Right Thigh',
      'Left Thigh',
      'Right Knee',
      'Left Knee',
      'Right Calf',
      'Left Calf',
      'Right Ankle',
      'Left Ankle',
    ];

    const lengthData = {
      'Right Shoulder': new Metric(
        'Right Shoulder',
        'Length from right high point shoulder to right shoulder point, along shoulder slope',
        112,
        302
      ),
      'Left Shoulder': new Metric(
        'Left Shoulder',
        'Length from left high point shoulder to left shoulder point, along shoulder slope',
        111,
        301
      ),
      'Right Arm': new Metric(
        'Right Arm',
        'Length along the outer right arm from the shoulder to elbow to wrist',
        110,
        326
      ),
      'Left Arm': new Metric(
        'Left Arm',
        'Length along the outer left arm from the shoulder to elbow to wrist',
        109,
        325
      ),
      'Shoulder Width': new Metric(
        'Shoulder Width',
        'Length from right shoulder point to left shoulder point over the back neck base',
        120,
        346
      ),
      'Across Chest': new Metric(
        'Across Chest',
        'Length from armpit to armpit across front along the surface of the body',
        119,
        329
      ),
      'Across Back': new Metric(
        'Across Back',
        'Length from armpit to armpit across back along the surface of the body',
        121,
        330
      ),
      'Back Neck to Right Bust': new Metric(
        'Back Neck to Right Bust',
        'Length from center back neck to right bust point',
        125,
        318
      ),
      'Back Neck to Left Bust': new Metric(
        'Back Neck to Left Bust',
        'Length from center back neck to left bust point',
        124,
        317
      ),
      'Right Side Pant Inseam': new Metric(
        'Right Side Pant Inseam',
        'Length from crotch to floor level, measured along the inner right leg',
        101,
        335
      ),
      'Left Side Pant Inseam': new Metric(
        'Left Side Pant Inseam',
        'Length from crotch to floor level, measured along the inner left leg',
        100,
        336
      ),
      'Right Outseam Waist To Floor': new Metric(
        'Right Outseam Waist To Floor',
        'Length from waist to floor level, measured along the outer right leg',
        103,
        -1
      ),
      'Left Outseam Waist To Floor': new Metric(
        'Left Outseam Waist To Floor',
        'Length from waist to floor level, measured along the outer left leg',
        102,
        -1
      ),
      'Right Outseam Waist To Knee': new Metric(
        'Right Outseam Waist To Knee',
        'Length from waist to knee level, measured along the outer right leg',
        105,
        -1
      ),
      'Left Outseam Waist To Knee': new Metric(
        'Left Outseam Waist To Knee',
        'Length from waist to knee level, measured along the outer left leg',
        104,
        -1
      ),
      'Total Crotch Length': new Metric(
        'Total Crotch Length',
        'Length from waist level center front to waist level center back, measured through the crotch',
        106,
        307
      ),
      'Front Crotch Length': new Metric(
        'Front Crotch Length',
        'Length from waist level center front to the crotch point',
        126,
        -1
      ),
      'Back Crotch Length': new Metric(
        'Back Crotch Length',
        'Length from waist level center back to the crotch point',
        127,
        -1
      ),
      'Front Rise - High': new Metric(
        'Front Rise - High',
        'Length from waistband center front at high rise level to the crotch point',
        138,
        -1
      ),
      'Back Rise - High': new Metric(
        'Back Rise - High',
        'Length from waistband center back at high rise level to the crotch point',
        139,
        -1
      ),
      'Front Rise - Mid': new Metric(
        'Front Rise - Mid',
        'Length from waistband center front at mid rise level to the crotch point',
        136,
        -1
      ),
      'Back Rise - Mid': new Metric(
        'Back Rise - Mid',
        'Length from waistband center back at mid rise level to the crotch point',
        137,
        -1
      ),
      'Front Rise - Low': new Metric(
        'Front Rise - Low',
        'Length from waistband center front at low rise level to the crotch point',
        134,
        -1
      ),
      'Back Rise - Low': new Metric(
        'Back Rise - Low',
        'Length from waistband center back at low rise level to the crotch point',
        135,
        -1
      ),
      'Left High Point Shoulder to Bust': new Metric(
        'Left High Point Shoulder to Bust',
        'Length from the left side of the neck to the left bust',
        115,
        313
      ),
      'Right High Point Shoulder to Bust': new Metric(
        'Right High Point Shoulder to Bust',
        'Length from the right side of the neck to the right bust',
        116,
        314
      ),
      'Left High Point Shoulder to Waist': new Metric(
        'Left High Point Shoulder to Waist',
        'Length from the left side of the neck to the waist over the left bust',
        117,
        -1
      ),
      'Right High Point Shoulder to Waist': new Metric(
        'Right High Point Shoulder to Waist',
        'Length from the right side of the neck to the waist over the right bust',
        118,
        -1
      ),
      'Nape to Waist Center Back': new Metric(
        'Nape to Waist Center Back',
        'Length from back neck base point to waist level along the center back',
        122,
        309
      ),
      'Nape to Upper Chest Center Back': new Metric(
        'Nape to Upper Chest Center Back',
        'Length from back neck base point to scye level along the center back',
        123,
        308
      ),
      'Hollow to Floor': new Metric(
        'Hollow to Floor',
        'Length from front neck base point to floor level along the center front',
        128,
        -1
      ),
      'Apex to Apex': new Metric(
        'Apex to Apex',
        'Length from left to right bust apex point',
        129,
        -1
      ),
    };

    const lengthOrder = [
      'Right Shoulder',
      'Left Shoulder',
      'Right Arm',
      'Left Arm',
      'Shoulder Width',
      'Across Chest',
      'Across Back',
      'Back Neck to Right Bust',
      'Back Neck to Left Bust',
      'Right High Point Shoulder to Bust',
      'Left High Point Shoulder to Bust',
      'Apex to Apex',
      'Right High Point Shoulder to Waist',
      'Left High Point Shoulder to Waist',
      'Hollow to Floor',
      'Nape to Upper Chest Center Back',
      'Nape to Waist Center Back',
      'Total Crotch Length',
      'Front Crotch Length',
      'Back Crotch Length',
      'Front Rise - High',
      'Back Rise - High',
      'Front Rise - Mid',
      'Back Rise - Mid',
      'Front Rise - Low',
      'Back Rise - Low',
      'Right Side Pant Inseam',
      'Left Side Pant Inseam',
      'Right Outseam Waist To Floor',
      'Left Outseam Waist To Floor',
      'Right Outseam Waist To Knee',
      'Left Outseam Waist To Knee',
    ];

    const metrics = {
      circData,
      circOrder,
      lengthData,
      lengthOrder,
      hasLockedGirthMetrics: false,
      hasLockedLengthMetrics: false,
      lockedGirthMetrics: [],
      unlockedGirthMetrics: circOrder,
      lockedLengthMetrics: [],
      unlockedLengthMetrics: lengthOrder,
    };

    getIdToKeyMappings(metrics);

    await setUnlockedMetrics(metrics, scanId);
    await getExternalMetrics(metrics, scanId);
    await getTTFMetrics(metrics, scanId);
    if (!hasLegScan) {
      excludeLegMetrics(metrics);
    }
    setMetricsLoading(false);
    return metrics;
  };

  const getIdToKeyMappings = (metrics) => {
    for (const source of Object.values(sources)) {
      for (const key in metrics.circData) {
        if (metrics.circData[key][source.id]) {
          source.girthIdToKey[metrics.circData[key][source.id]] = key;
        }
      }
      for (const key in metrics.lengthData) {
        if (metrics.lengthData[key][source.id]) {
          source.lengthIdToKey[metrics.lengthData[key][source.id]] = key;
        }
      }
    }
  };

  const getPlanData = async (scanId) => {
    if (!scanId) throw new Error('No scanId provided');
    await queryClient.invalidateQueries('user');
    const userData = queryClient.getQueryData('user');
    const { accountType, userDocRef, isAdmin } = { ...userData };
    // check if the user exists
    if (!data || !userDocRef) {
      // retrun the data for the free plan
      return {
        unlockedGirthMetrics: {
          2: 'Bust',
          3: 'Waist',
          5: 'Hip',
        },
        unlockedLengthMetrics: {
          100: 'Left Side Pant Inseam',
          101: 'Right Side Pant Inseam',
          113: 'Height',
        },
        isDefaultPlan: true,
        avatarPending: true,
        canDownloadMetrics: false,
        canDownloadScans: false,
      };
    }
    // check if the avatar is pending for the user
    let avatarPending = false;
    const sharedScanDoc = await userDocRef
      .collection('shared_scans')
      .doc(scanId)
      .get();
    if (!sharedScanDoc.exists) {
      avatarPending = true;
    } else {
      const sharedScanData = sharedScanDoc.data();
      if (sharedScanData.shareStatus !== 'APPROVED') {
        avatarPending = true;
      }
    }
    if (isAdmin) {
      avatarPending = false;
    }
    setAvatarIsPending(avatarPending);

    const planDoc = await db.collection('plans').doc(accountType).get();
    const planData = planDoc?.data();
    if (!planData) return { avatarPending };
    const billingInfo = await userDocRef
      .collection('invoices')
      .doc('billingInfo')
      .get();
    const billingInfoData = billingInfo.data();
    if (billingInfoData.isCustomPlan) {
      const customPlan = await userDocRef
        .collection('invoices')
        .doc('customPlan')
        .get();
      const customPlanData = customPlan.data();
      return {
        ...planData,
        ...customPlanData,
        avatarPending,
        canDownloadMetrics: avatarPending ? false : planData.canDownloadMetrics,
        canDownloadScans: avatarPending ? false : planData.canDownloadScans,
      };
    }
    return {
      ...planData,
      avatarPending,
      canDownloadMetrics: avatarIsPending ? false : planData.canDownloadMetrics,
      canDownloadScans: avatarIsPending ? false : planData.canDownloadScans,
    };
  };

  const setUnlockedMetrics = async (metrics, scanId) => {
    const planData = await getPlanData(scanId);
    // lock all metrics if plan is default or avatar is unlocked
    if (planData.isDefaultPlan || planData.avatarPending) {
      const allMetrics = Object.values(metrics.circData).concat(
        Object.values(metrics.lengthData)
      );
      allMetrics.forEach((metric) => {
        metric.lock();
      });
    }
    const { unlockedGirthMetrics = {}, unlockedLengthMetrics = {} } = planData;
    const { id } = sources.inhouse;

    if (planData.unlockedGirthMetrics) {
      Object.keys(metrics.circData).forEach((metricKey) => {
        const metricId = metrics.circData[metricKey][id];
        if (!unlockedGirthMetrics.hasOwnProperty(metricId)) {
          const metric = metrics.circData[metricKey];
          // unlock the metric if it is not a child of an unlocked metric
          metric.lock();
        }
      });
    }

    if (planData.unlockedLengthMetrics) {
      Object.keys(metrics.lengthData).forEach((metricKey) => {
        const metricId = metrics.lengthData[metricKey][id];
        if (!unlockedLengthMetrics.hasOwnProperty(metricId)) {
          const metric = metrics.lengthData[metricKey];
          // unlock the metric if it is not a child of an unlocked metric
          metric.lock();
        }
      });
    }
    // now sort the reorder the metrics to put the unlocked metrics first
    const theUnlockedGirthMetrics = Object.values(unlockedGirthMetrics);
    const theUnlockedLengthMetrics = Object.values(unlockedLengthMetrics);

    const filteredCircOrder = metrics.circOrder.filter(
      (metricKey) => !theUnlockedGirthMetrics.includes(metricKey)
    );
    const filteredLengthOrder = metrics.lengthOrder.filter(
      (metricKey) => !theUnlockedLengthMetrics.includes(metricKey)
    );
    // now add the unlocked metrics to the front of the list
    metrics.circOrder = theUnlockedGirthMetrics.concat(filteredCircOrder);
    metrics.lengthOrder = theUnlockedLengthMetrics.concat(filteredLengthOrder);
    // if it has locked metrics, update the metrics data object
    if (theUnlockedGirthMetrics.length > 0) {
      metrics.hasLockedGirthMetrics = true;
      metrics.unlockedGirthMetrics = theUnlockedGirthMetrics;
      metrics.lockedGirthMetrics = filteredCircOrder;
    }
    if (theUnlockedLengthMetrics.length > 0) {
      metrics.hasLockedLengthMetrics = true;
      metrics.unlockedLengthMetrics = theUnlockedLengthMetrics;
      metrics.lockedLengthMetrics = filteredLengthOrder;
    }
  };

  const excludeLegMetrics = (metrics) => {
    metrics.circOrder = metrics.circOrder.filter(
      (circ) => !CircumferenceLegMetrics.includes(circ)
    );
    metrics.lengthOrder = metrics.lengthOrder.filter(
      (len) => !LengthsLegMetrics.includes(len)
    );
  };

  const getExternalMetrics = async (metrics, scanId) => {
    const metricsDoc = await db
      .collection(sources.external.collection)
      .doc(scanId)
      .get();
    if (!metricsDoc.data() || !metricsDoc.data().version) {
      // Skip over old metrics that don't have a version field
      return;
    }
    await getGirthMetrics(sources.external, metrics, scanId);
    await getLengthMetrics(sources.external, metrics, scanId);
  };

  const getTTFMetrics = async (metrics, scanId) => {
    await getGirthMetrics(sources.inhouse, metrics, scanId);
    await getLengthMetrics(sources.inhouse, metrics, scanId);
  };

  // meters to in
  const convert_to_in = (m) => Math.round(m * 3937.01) / 100;

  const getGirthMetrics = async (source, metrics, scanId) => {
    const girthArrays = [];
    let tempGirthArray = [];
    for (const key in metrics.circData) {
      if (metrics.circData[key][source.id]) {
        tempGirthArray.push(metrics.circData[key][source.id]);
      }
      if (tempGirthArray.length === 10) {
        girthArrays.push(tempGirthArray);
        tempGirthArray = [];
      }
    }
    if (tempGirthArray.length !== 0) {
      girthArrays.push(tempGirthArray);
    }
    let girthMetrics = [];
    for (let i = 0; i < girthArrays.length; i++) {
      const tempGirthMetrics = (
        await db
          .collection(source.collection)
          .doc(scanId)
          .collection('girths')
          .where('id', 'in', girthArrays[i])
          .get()
      ).docs;
      girthMetrics = girthMetrics.concat(tempGirthMetrics);
    }
    for (const key in girthMetrics) {
      const metric = girthMetrics[key].data();
      const label = source.girthIdToKey[metric.id];
      const metricObject = metrics.circData[metric.id];
      if (metric.girth && label) {
        metrics.circData[label].setValue(convert_to_in(metric.girth));
        metrics.circData[label].points = metric.pointcollection;
      }
    }
    // filter out the metrics that don't exist for this scan
    const supportedLabels = girthMetrics.map((metric) => metric.data().label);
    metrics.circOrder = metrics.circOrder.filter((label) =>
      supportedLabels.includes(label)
    );
    metrics.unlockedGirthMetrics = metrics.unlockedGirthMetrics.filter(
      (label) => supportedLabels.includes(label)
    );
    metrics.lockedGirthMetrics = metrics.lockedGirthMetrics.filter((label) =>
      supportedLabels.includes(label)
    );
    // delete metrics from circData that don't exist for this scan
    for (const key in metrics.circData) {
      if (!supportedLabels.includes(key)) {
        delete metrics.circData[key];
      }
    }
  };

  const getLengthMetrics = async (source, metrics, scanId) => {
    const lengthArrays = [];
    let tempLengthArray = [];
    for (const key in metrics.lengthData) {
      if (metrics.lengthData[key][source.id]) {
        tempLengthArray.push(metrics.lengthData[key][source.id]);
      }
      if (tempLengthArray.length === 10) {
        lengthArrays.push(tempLengthArray);
        tempLengthArray = [];
      }
    }
    if (tempLengthArray.length !== 0) {
      lengthArrays.push(tempLengthArray);
    }
    let lengthMetrics = [];
    for (let i = 0; i < lengthArrays.length; i++) {
      const tempLengthMetrics = (
        await db
          .collection(source.collection)
          .doc(scanId)
          .collection('surfaceLengths')
          .where('id', 'in', lengthArrays[i])
          .get()
      ).docs;
      lengthMetrics = lengthMetrics.concat(tempLengthMetrics);
    }
    for (const key in lengthMetrics) {
      const metric = lengthMetrics[key].data();
      const label = source.lengthIdToKey[metric.id];
      const metricObject = metrics.lengthData[metric.id];
      if (metric.length && label) {
        metrics.lengthData[label].setValue(convert_to_in(metric.length));
        metrics.lengthData[label].points = metric.points;
      }
    }
    // filter out the metrics that don't exist for this scan
    const supportedLabels = lengthMetrics.map((metric) =>
      metric.data().label?.replace(/Centre/g, 'Center')
    );

    metrics.lengthOrder = metrics.lengthOrder.filter((label) =>
      supportedLabels.includes(label)
    );
    metrics.unlockedLengthMetrics = metrics.unlockedLengthMetrics.filter(
      (label) => supportedLabels.includes(label)
    );
    metrics.lockedLengthMetrics = metrics.lockedLengthMetrics.filter((label) =>
      supportedLabels.includes(label)
    );
    // delete metrics from lengthData that don't exist for this scan
    for (const key in metrics.lengthData) {
      if (!supportedLabels.includes(key)) {
        delete metrics.lengthData[key];
      }
    }
  };

  const value = {
    ...data, // user data
    sources,
    getPlanData,
    getMetrics,
    metricsLoading,
    avatarIsPending,
  };

  return (
    <MeasurementContext.Provider value={value}>
      {children}
    </MeasurementContext.Provider>
  );
};

export { MeasurementContext, MeasurementContextProvider };
